body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.success{
  background-color: #96ceb4;
}

.danger{
  background-color: #ff6f69;
}

.info{
  background-color: #ffeead;
}


.warning{
  background-color: #ffcc5c;
}

.todo_menu_today {
  color: #ff3366 !important;
}

.todo_menu_remaining {
  color: #66dd66 !important;
}

.fc-day-other {
  filter: grayscale(0.75);
}

.selected-button {
  background: green !important;
}

.half-day {
  width: 50% !important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
.link:hover {
  color: #40a9ff;
}
.link:active {
  color: #096dd9;
}
.link:active,
.link:hover {
  text-decoration: none;
  outline: 0;
}
.link[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
